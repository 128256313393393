import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AgeCategoryInterface, ResourceBlockInterface } from './resource-block.interface';

@Component({
  selector: 'dk-resource-block',
  templateUrl: './resource-block.component.html',
  styleUrls: ['./resource-block.styles.scss'],
})
export class ResourceBlockComponent implements OnInit {
  @Input('data-block') blockData: ResourceBlockInterface;
  ageCategory: AgeCategoryInterface;
  description;
  constructor(private _domSanitizer: DomSanitizer) {}
  ngOnInit() {
    this.ageCategory = this.getAgeCategory(this.blockData.age_category);
    this.description = this._domSanitizer.bypassSecurityTrustHtml(this.blockData.description);
  }

  getAgeCategory(ageCat: string): AgeCategoryInterface {
    // Find first number in age category e.g. for "Ages 3-9", firstNumber = "3"
    if (!ageCat) {
      return null;
    }
    const numberExpression = /\d+/;
    const firstNumber = ageCat.match(numberExpression);
    try {
      if (firstNumber[0]) {
        const index = ageCat.indexOf(firstNumber[0]);
        return {
          ageLabel: ageCat.substring(0, index).trim(),
          ageGroup: ageCat.substring(index).trim(),
        };
      }
    } catch (e) {
      // No number present in age category, e.g. "ageCat: 'preschool'"
      return {
        ageLabel: ageCat,
        ageGroup: null,
      };
    }
  }
}
