import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { akitaDevtools } from '@datorama/akita';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { SharedModule } from 'app/modules/shared/shared.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
// Load Components
import { AppComponent } from './app.component';
import { LoadingComponent } from './components/atoms/loading/loading.component';
import { CustomHammerConfig } from './services/hammerconfig.service';

if (!environment.production) {
  akitaDevtools();
}

/*
 * Make sure urls end with trailing slash
 * https://github.com/angular/angular/issues/16051#issuecomment-411397242
 */
export class CustomeUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url);
  }
  serialize(tree: UrlTree): any {
    const dus = new DefaultUrlSerializer();
    let path = dus.serialize(tree);
    const pathArr = path.split('?');
    pathArr[0] += '/';
    const joinSeparator = pathArr.length > 1 ? '?' : '';
    path = pathArr.join(joinSeparator);
    return path;
  }
}

@NgModule({
  declarations: [AppComponent, LoadingComponent],
  imports: [
    AkitaNgRouterStoreModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'dk' }),
    HttpClientJsonpModule,
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    TransferHttpCacheModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: UrlSerializer, useClass: CustomeUrlSerializer },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig,
    },
  ],
})
export class AppModule {}
