<ng-container *ngIf="{ header: pageHeader$ | async, labels: labels$ | async } as data">
	<ng-container *ngIf="data.header && data.labels">
		<header class="page-header">
			<section class="page-header__section h__page-container">
				<button class="page-header__burger" (click)="toggleNav()"
					[ngClass]="{ 'page-header__burger--close': showNav, 'page-header__icon__hidden': searchActive }"
					aria-label="Menu">
					<dk-icon svgUrl="assets/images/burger-menu.svg" class="on-hover-keep-color dk-icon--f-black dk-icon--w-20"
						focusable="false"></dk-icon>
				</button>
				<a class="page-header__logo" [ngClass]="{ 'page-header__icon__hidden': searchActive }"
					[routerLink]="'/' + country + '/'"><img src="assets/images/dk-logo.svg" alt="Dorling Kindersley logo" /></a>
				<dk-search-bar *ngIf="!data.header.hideHeader" (openedQuick)="isSearchActive($event)"
					[ngClass]="{ 'search-bar__extended': searchActive }"></dk-search-bar>
				<a *ngIf="country != 'cn'" class="page-header__newsletter-icon"
					[ngClass]="{ 'page-header__newsletter-icon__hidden-header': data.header.hideHeader }"
					[routerLink]="'/' + country + '/' + 'newsletter/'">
					<dk-icon class="dk-icon--w-25" svgUrl="assets/images/icon-newsletter.svg"></dk-icon><span>{{
						data.labels.headerNewsletterCTA }}</span>
				</a>
			</section>
		</header>
		<!--Top level Categories-->
		<nav *ngIf="!data.header.hideHeader" class="page-header__nav" [ngClass]="{ loading: isLoading }">
			<section class="page-header__topcat">
				<div class="page-header__topcat-inner h__page-container">
					<!--START: All Books Button-->
					<button class="button button--icon page-header__all-books" (mouseenter)="openNav()" (mouseleave)="closeNav()"
						[ngClass]="{ 'page-header__all-books--open rotate-icon-180': showNav }">
						<label class="h__bold">{{ data.labels.headerNavDropdown }}</label>
						<dk-icon svgUrl="assets/images/arrow-down.svg" class="on-hover-keep-color dk-icon--f-black dk-icon--w-10">
						</dk-icon>
					</button>
					<!--END: All Books Button-->
					<ul itemscope itemtype="http://www.schema.org/SiteNavigationElement">
						<li *ngFor="let cat of data.header.topCat" itemprop="name">
							<a itemprop="url" [routerLink]="cat.url | lowercase">{{ cat.title }}</a>
						</li>
					</ul>
					<ul>
						<li *ngFor="let promo of data.header.topPromo">
							<a [routerLink]="promo.url | lowercase">{{ promo.title }}</a>
						</li>
					</ul>
					<span class="fade"></span>
				</div>
			</section>
		</nav>
		<!--All Categories-->
		<nav *ngIf="!data.header.hideHeader" class="page-header__cat" [ngClass]="{ 'page-header__cat--open': showNav }"
			(mouseenter)="openNav()" (mouseleave)="closeNav()">
			<div class="page-header__cat-inner">
				<section *ngFor="let col of data.header.categories; let si = index">
					<h4 class="h__bold">{{ col.topTitle }}</h4>
					<ul>
						<ng-template ngFor let-sub let-i="index" [ngForOf]="col.subTitles">
							<li [ngClass]="{ hide: i > 4 && !showExtendedCategories }">
								<a (click)="toggleNav()" [routerLink]="sub.url | lowercase">{{ sub.title }}</a>
							</li>
						</ng-template>
						<li *ngIf="si === 0" class="page-header__cat__toggle" (click)="toggleExtendedCategories()">
							{{ showExtendedCategories ? data.labels.headerNavLessCategories : data.labels.headerNavMoreCategories }}
						</li>
					</ul>
				</section>
			</div>
		</nav>
	</ng-container>
</ng-container>