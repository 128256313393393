import { Injectable } from '@angular/core';
import { del, set, Store } from 'idb-keyval';
import { Observable } from 'rxjs';
import { FormatsInterface, ProductInterface } from '../product.interface';
import { createProduct } from './product.model';
import { FormatQuery, ProductQuery } from './product.query';
import { FormatStore, ProductStore } from './product.store';

const productStore = new Store('dk-idb', 'product-store');

@Injectable({
  providedIn: 'root',
})
export class ProductStoreService {
  constructor(
    private _productStore: ProductStore,
    private _productQuery: ProductQuery,
    private _formatStore: FormatStore,
    private _formatQuery: FormatQuery
  ) {}

  /**
   * Adds a product to the store, marks it as active and then adds it to IDB cache.
   * @param product The product to add to store
   */
  addProductToStore(product: ProductInterface) {
    const _product = createProduct(product);
    this._productStore.add(_product);
    this._productStore.setActive(_product.url);
    // Add product to IDB
    set(_product.url, _product, productStore);
  }

  /**
   * Removes a book from the Product Store and from IDB
   *
   * The product page subscribes the the Product store, so we can't remove an active product
   * @param url The url of the book we want to remove
   */
  deleteBookfromStore(url: string) {
    const currentURL = this._productQuery.getActiveId();
    if (currentURL !== url) {
      this._productStore.remove(url);
    }
    return del(url, productStore);
  }

  hasProduct(url: string): boolean {
    return this._productQuery.hasEntity(url);
  }

  setProductActive(url: string) {
    this._productStore.setActive(url);
  }

  setActiveFormat(formatQuery: string) {
    const { formats } = this._productQuery.getActive();
    if (formatQuery.startsWith('978')) {
      // TODO: API should return `isbn` field as a string
      // tslint:disable-next-line: triple-equals
      const filteredFormats = formats.filter(format => format.isbn13 == formatQuery);
      if (filteredFormats.length) {
        const matchingFormat = filteredFormats[0].format;
        this._formatStore.setActive(matchingFormat);
      } else {
        this._formatStore.setActive(formats[0].format);
      }
    } else {
      this._formatStore.setActive(formatQuery);
    }
  }

  setActiveFormatByIndex(index: number) {
    const products = this._productQuery.getActive();
    const formats = products.formats;
    this._formatStore.setActive(formats[index].format);
  }

  getActiveProduct(): ProductInterface {
    return this._productQuery.getActive();
  }

  getActiveFormat(): FormatsInterface {
    return this._formatQuery.getActive();
  }

  selectActiveFormat(): Observable<FormatsInterface> {
    return this._formatQuery.selectActive();
  }

  selectActiveProduct(): Observable<ProductInterface> {
    return this._productQuery.selectActive();
  }

  selectProductByURL(url: string) {
    return this._productQuery.selectEntity(url);
  }

  selectProductsByCountry(country: string, limit?: number) {
    return this._productQuery.selectAll({
      limitTo: limit ? limit : undefined,
      filterBy: book => book.url.includes(`/${country}/`),
    });
  }

  updateFormatStore() {
    const product = this._productQuery.getActive();
    const formats = product.formats;
    this._formatStore.set(formats);
  }

  /**
   * Updates the product store record
   * @param product The product to be updated
   */
  updateProductInStore(product: ProductInterface) {
    const _product = createProduct(product);
    this._productStore.update(_product.url, _product);
    this._formatStore.update(_product.formats);
    set(_product.url, _product, productStore);
  }
}
