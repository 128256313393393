import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResourceGridComponent } from './components/molecules/resource-grid/resource-grid.component';
import { FourohfourComponent } from './components/pages/fourohfour/fourohfour.component';

const routes: Routes = [
  // { path: '', redirectTo: '/uk', pathMatch: 'full' },

  {
    path: ':country/article/:article',
    loadChildren: () => import('./components/pages/article/article.module').then(m => m.ArticleModule),
  },
  {
    path: ':country/information/:infopage',
    loadChildren: () => import('./components/pages/article/article.module').then(m => m.ArticleModule),
  },
  {
    path: ':country/book/:bookUrl',
    loadChildren: () => import('./components/pages/product/product.module').then(m => m.ProductModule),
  },
  {
    path: ':country/book/:bookUrl/:format',
    loadChildren: () => import('./components/pages/product/product.module').then(m => m.ProductModule),
  },
  {
    path: ':country/category/:category',
    loadChildren: () => import('./components/pages/category/category.module').then(m => m.CategoryModule),
  },
  {
    path: ':country/promotion/:promopage',
    loadChildren: () => import('./components/pages/category/category.module').then(m => m.CategoryModule),
  },
  {
    path: ':country/newsletter',
    loadChildren: () => import('./components/pages/newsletter/newsletter.module').then(m => m.NewsletterModule),
  },
  {
    path: ':country/search/:query',
    loadChildren: () => import('./components/pages/search/search.module').then(m => m.SearchModule),
  },
  {
    path: ':country/gifts',
    loadChildren: () => import('./components/pages/gifts/gifts.module').then(m => m.GiftsModule),
  },
  {
    path: ':country',
    loadChildren: () => import('./components/pages/homepage/homepage.module').then(m => m.HomepageModule),
  },
  { path: ':country/404', component: FourohfourComponent },
  // TODO Remove the test route once done
  { path: ':country/test', component: ResourceGridComponent },
  { path: '**', redirectTo: '/uk' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
