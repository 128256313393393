import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SolrResultInterface } from 'app/components/pages/search/search.interface';
import { environment } from 'environments/environment';
import { Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MailmanInterface } from './mailman.interface';

@Injectable({
  providedIn: 'root',
})
/**
 * Service to interact with DK API
 */
export class MailmanService {
  constructor(private _http: HttpClient) {}

  /**
   * Returns an Observable from dk.com API
   * @param endpoint string
   */
  getApi<R>(endpoint: string): Observable<R> {
    endpoint = `${endpoint}.json`;
    return this._http.get<MailmanInterface<R>>(environment.dkApiUrl + endpoint).pipe(
      map(response => {
        if (typeof response.result === 'object' && Object.keys(response.result).length) {
          return response.result;
        } else {
          throw observableThrowError(response.error);
        }
      })
    );
  }

  // This is a temp function until we migrate everithing to the API
  getData<R>(endpoint: string): Observable<MailmanInterface<R>> {
    return this._http.get<MailmanInterface<R>>(environment.awsApiUrl + endpoint).pipe(
      map(response => {
        if (typeof response.result === 'object' && Object.keys(response.result).length) {
          return response;
        } else {
          throw observableThrowError(response);
        }
      })
    );
  }

  /**
   * Returns a search result from SOLR.
   * A full formated query is required, ie
   * `fq=DocumentTypeString:Product&fq=LanguageCode:en-GB&rows=3&wt=json&q=lego`
   * @param term string
   */
  searchBooks(term: string): Observable<MailmanInterface<SolrResultInterface>> {
    const options = {
      headers: new HttpHeaders().set('Authorization', environment.solrAuth),
    };

    let response: MailmanInterface<SolrResultInterface>;
    return this._http.get<SolrResultInterface>(environment.searchUrl + term, options).pipe(
      map(result => {
        response = {
          result: result,
        };
        return response;
      }),
      catchError(error => {
        console.error(error);
        response = {
          error: {
            code: error.status,
            message: error.message,
          },
        };
        // update heartbeat

        return observableOf(response);
      })
    );
  }
}
