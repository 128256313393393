import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { get, keys, Store } from 'idb-keyval';
import { FormatsInterface, ProductInterface } from '../product.interface';

const productStore = new Store('dk-idb', 'product-store');

export interface ProductState extends EntityState<ProductInterface, string>, ActiveState {}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'product', idKey: 'url' })
export class ProductStore extends EntityStore<ProductState> {
  constructor() {
    super();
    this._loadBooksFromIDB();
  }

  /**
   * Loads all books from IDB store and adds them to memory store.
   */
  private async _loadBooksFromIDB() {
    const iDBkeys = await keys(productStore);
    const booksPromise: Promise<ProductInterface>[] = [];
    if (iDBkeys.length) {
      for (const key of iDBkeys) {
        booksPromise.push(get<ProductInterface>(key, productStore));
      }
      Promise.all(booksPromise).then(books => this.add(books));
    }
  }
}

export interface FormatState extends EntityState<FormatsInterface, string>, ActiveState {}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'format', idKey: 'format' })
export class FormatStore extends EntityStore<FormatState> {
  constructor() {
    super();
  }
}
