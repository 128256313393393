<ng-container
  *ngIf="{
    footer: footer$ | async,
    market: activemarket$ | async,
    labels: labels$ | async
  } as data"
>
  <div class="footer-container" *ngIf="isBrowser && data.footer && data.market">
    <div class="footer-upper">
      <div class="footer-upper-inner">
        <ul class="follow-dk-social-icons">
          <li class="follow-text">{{ data.labels.footerSocialCTA }}</li>
          <li *ngFor="let social of data.footer.socialLinks">
            <a
              class="h__icon-link"
              href="{{ social.url }}"
              target="_blank"
              rel="noopener"
              title="{{ social.tootltip }}"
            >
              <dk-icon class="dk-icon--h-20 dk-icon--hover-black" externalSvgUrl="{{ social.icon }}"></dk-icon>
            </a>
          </li>
        </ul>
        <a
          *ngIf="data.market.code != 'cn'"
          class="h__bold footer-container__newsletter-signup"
          [routerLink]="[urlNewsletter]"
          routerLinkActive="router-link-active"
        >
          <dk-icon class="dk-icon--h-20 dk-icon--hover-black" svgUrl="assets/images/newsletter.svg"></dk-icon>
          <label>{{ data.labels.footerNewsletterCTA }}</label>
        </a>
        <button class="button change-lang-btn-container" (click)="showModal('changeCountry')">
          <span
            class="footer-flag"
            [style.background-image]="'url(assets/images/flag/' + data.market.code + '.svg)'"
          ></span>
          <span
            >{{ data.market.name }}
            <span class="change h__bold">{{ data.labels.footerChangeCountry }}</span>
          </span>
        </button>
      </div>
    </div>

    <dk-modal
      modalTitle="{{ data.labels.changeCountryTitle }}"
      *ngIf="'changeCountry' === (modalState | async)"
      modalName="changeCountry"
    >
      <dk-change-country [countryData]="data.footer.countries"></dk-change-country>
    </dk-modal>

    <div class="footer-links">
      <div class="footer-links-inner">
        <div
          class="footer-links-container {{ col.section }}"
          *ngFor="let col of data.footer.list"
          [ngClass]="{ 'footer-links-container--active': selectedItem == col.section }"
        >
          <h2 class="h__bold" (click)="toggleMenu(col.section)">
            <span>{{ col.toptitle }}</span>
            <dk-icon svgUrl="assets/images/arrow-down.svg" class="small-icon"></dk-icon>
          </h2>
          <ul>
            <li *ngFor="let subContent of col.subtitles">
              <a href="{{ subContent.url }}">{{ subContent.title }}</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer-bottom">
        <img src="assets/images/prh-logo.svg" alt="Penguin Random House logo" />
        <p class="copyright">{{ data.footer.companyDetails }}<br />{{ data.footer.companyTrademarks }}</p>
      </div>
    </div>
  </div>
</ng-container>
