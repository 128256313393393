import { empty as observableEmpty, Observable } from 'rxjs';
import { ElementRef, Inject, Injectable, Renderer2, SecurityContext, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { map, catchError } from 'rxjs/operators';

class SvgIconConfig {
  svgElement: SVGElement | null = null;
  constructor(public url: SafeResourceUrl) {}
}

@Injectable()
export class DkIconService {
  constructor(
    private _elementRef: ElementRef,
    private _http: HttpClient,
    private _renderer: Renderer2,
    private _sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  getIconFromUrl(url: string) {
    return this.fetchUrl(url);
  }

  setSvgElement(svg: SVGElement) {
    const elem = this._elementRef.nativeElement;
    this._renderer.appendChild(elem, svg);
  }

  /**
   * Returns an SVG from a URL
   */
  private fetchUrl(safeUrl: SafeResourceUrl): Observable<SVGElement | any> {
    const url = this._sanitizer.sanitize(SecurityContext.URL, safeUrl);

    return this._http.get(url, { responseType: 'text' }).pipe(
      map(res => this.svgElementFromString(res)),
      catchError(error => {
        console.error(`Can't get ${url} because: ${error}`);
        return observableEmpty();
      })
    );
  }

  /**
   * Creates a DOM element from the given SVG string.
   */
  private svgElementFromString(str: string): SVGElement {
    const div = this._document.createElement('DIV');
    this._renderer.setProperty(div, 'innerHTML', str);
    const svg = div.querySelector('svg') as SVGElement;
    if (!svg) {
      throw Error('<svg> tag not found');
    }
    return svg;
  }
}
